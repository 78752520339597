<template>
  <div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script type="ts" setup>

</script>
